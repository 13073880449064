import React, {useState, useEffect} from 'react';
import {Box} from '@material-ui/core'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import NewConversationDialog from "./NewConversationDialog";
import {DataGrid} from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import {useHistory} from "react-router-dom";
import {DateTime} from "luxon";
import firebase from "firebase/app";
import TextField from '@material-ui/core/TextField';

function Dashboard(props) {
	const [open, setOpen] = React.useState(false);
	const [conversations, setConversations] = useState([]);
	const [searchName, setSearchName] = useState("")
	const [searchPhone, setSearchPhone] = useState('')
	const [user, setUser] = useState(null);
	const [search, setSearch] = useState(1)
	const handleClickOpen = () => {
		setOpen(true);
	};
	let history = useHistory();

	const columns = [
		{field: 'to', headerName: 'Phone', width: 150},
		{field: 'name', headerName: 'Name', width: 150},
		{field: 'permissionGranted', headerName: 'Permission', width: 150},
		{field: 'agent', headerName: "Assigned To", width: 200},
		{field: 'lastMsgFromMember', headerName: 'Needs Response', width: 200},
		{
			field: 'time', headerName: "Msg Time", sortable: true, width: 200,
			renderCell: (params) => (
				<p>{DateTime.fromISO(params.row.time.toDate().toISOString()).setZone("America/Los_Angeles").toLocaleString(DateTime.DATETIME_MED)}</p>
			)
		},
		{
			field: 'actions', headerName: "Actions", sortable: false, width: 500,
			renderCell: (params) => (
				<div>

					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							console.log("params ", params)
							history.push("/chat/" + params.id)
						}}
						disabled={!params.row.permissionGranted}
					>
						View
					</Button>


					<Button
						variant="contained"
						color="primary"
						style={{marginLeft: 10}}
						onClick={async () => {
							var agent = prompt("Email of agent to reassign to");

							if (agent && agent !== "") {
								props.db.collection("convoManager").doc(params.id).update({
									"agent": agent
								})
								await getDocs(search)
							}

						}}
					>
						Reassign
					</Button>
					<Button
						variant="contained"
						color="primary"
						style={{marginLeft: 10}}
						onClick={() => {
							props.db.collection("convoManager").doc(params.id).update({
								"archived": true
							})
							getDocs(search)
						}}
					>
						Archive
					</Button>
				</div>

			)
		}
	];

	async function getUser(email) {
		let docRef = await props.db.collection("users").doc(email).get();
		console.log(docRef)
		if (docRef.exists) {
			let data = docRef.data()
			console.log("data ", data)
			setUser(data)
		}
	}

	useEffect(() => {
		console.log("here in get user")
		getUser(props.user.email)
		console.log('get user done')
	}, [props.user])

	async function getDocs(months = 1) {
		setSearch(months)
		var now = DateTime.local().minus({months: months});
		let dateNow = new Date(now.toISO())
		console.log(dateNow)
		console.log("user email", props.user.email)
		let fbDate = firebase.firestore.Timestamp.fromMillis(dateNow)
		console.log('date now ', dateNow)
		let docs = []
		if (user?.type === "admin" && months !== -1) {
			let snap = await props.db.collection("convoManager")
				.where("time", ">=", fbDate)
				.where("archived", "==", false)
				.orderBy("time", "desc")
				.get()
			snap.forEach((doc) => {
				docs.push({"id": doc.id, ...doc.data()})
			})
			setConversations(docs)
		} else if (months === -1) {
			console.log("here in archived")

			let snap = await props.db.collection("convoManager")
				.where("archived", "==", true)
				.get()
			snap.forEach((doc) => {
				docs.push({"id": doc.id, ...doc.data()})
			})

			setConversations(docs)
			console.log("docs ", docs)
		} else {
			let snap = await props.db.collection("convoManager")
				.where("time", ">=", fbDate)
				.where("archived", "==", false)
				.orderBy("time", "desc")
				.get()
			snap.forEach((doc) => {
				//				.where("agent", "==", props.user.email)
				if (doc.data().agent === props.user.email) {
					docs.push({"id": doc.id, ...doc.data()})
				}

			})
			setConversations(docs)
		}

	}

	async function getDocsByName(name) {
		let docs = []
		let snap = await props.db.collection("convoManager")
			.where("name", "==", name)
			.get()
		snap.forEach((doc) => {
			docs.push({"id": doc.id, ...doc.data()})
		})
		setConversations(docs)
	}

	async function getDocsByPhone(phone) {
		let docs = []
		let snap = await props.db.collection("convoManager")
			.where("to", "==", "+1" + phone)

			.get()
		snap.forEach((doc) => {
			docs.push({"id": doc.id, ...doc.data()})
		})
		setConversations(docs)
	}

	useEffect(() => {
		getDocs(1);

	}, [user])


	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			flexDirection={"column"}
		>

			<Fab color="primary" aria-label="add" style={{marginTop: 20}}>
				<AddIcon onClick={handleClickOpen}/>
			</Fab>
			<NewConversationDialog open={open} setOpen={setOpen} db={props.db} user={props.user} getDocs={getDocs}/>
			<div style={{height: 700, width: '100%', marginTop: 30}}>
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					flexDirection={"row"}
				>
					<Button
						onClick={() => getDocs(1)}
					>Past 30 Days</Button>
					<Button onClick={() => getDocs(2)}>Past 60 days</Button>
					<Button onClick={() => getDocs(3)}>Past 90 days</Button>
					<Button onClick={() => getDocs(-1)}>Archived</Button>
				</Box>
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					flexDirection={"row"}
				>
					<TextField
						id="standard-basic"
						label="Search By Name"
						style={{width: 200}}
						value={searchName}
						onChange={(e) => setSearchName(e.target.value)}
					/>
					<Button onClick={() => {
						getDocsByName(searchName);
						setSearchName("")
					}}>Search</Button>
					<TextField
						id="standard-basic"
						label="Search By Phone"
						style={{width: 200}}
						value={searchPhone}
						onChange={(e) => setSearchPhone(e.target.value)}
					/>
					<Button onClick={() => {
						getDocsByPhone(searchPhone);
						setSearchPhone("")
					}}>Search</Button>
				</Box>
				<DataGrid
					rows={conversations}
					columns={columns}
					pageSize={10}
					isRowSelectable={(params) => false}
				/>
			</div>
		</Box>
	)
}

export default Dashboard

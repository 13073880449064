import {FirebaseAuthConsumer, FirebaseAuthProvider} from "@react-firebase/auth";
import firebase from "firebase/app";
import {StyledFirebaseAuth} from "react-firebaseui";
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import React, {useState} from 'react';
import 'react-chat-elements/dist/main.css';
import {Box} from '@material-ui/core'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link, useHistory
} from "react-router-dom";
import ChatBox from "./ChatBox";
import Dashboard from "./Dashboard";
import SignIn from "./SignIn";

// Configure FirebaseUI.
const uiConfig = {
	// Popup signin flow rather than redirect flow.
	signInFlow: 'popup',
	// Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
	signInSuccessUrl: '/',
	// We will display Google and Facebook as auth providers.
	signInOptions: [
		firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD

	],
};

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
}));


function Home(props) {
	const classes = useStyles();
	let history = useHistory();


	return (

		<FirebaseAuthConsumer>
			{({isSignedIn, user, providerId}) => {
				if (isSignedIn) {
					return (
						<Router>
							<div>
								<AppBar position="static">
									<Toolbar>


										<Typography variant="h6" className={classes.title}>
											Camino Loan Chat-to-Text
										</Typography>
										<Button color="inherit"
												onClick={() => firebase.auth().signOut()}>Logout</Button>
									</Toolbar>
								</AppBar>

								<Switch>
									<Route path="/chat/:chatID">
										<ChatBox db={props.db} user={user} />
									</Route>
									<Route path={"/"}>
										<Dashboard db={props.db} user={user} />
									</Route>

								</Switch>

							</div>
						</Router>
					)
				} else {
					return (
						<div>
							<AppBar position="static" >
								<Toolbar>
									<IconButton edge="start" className={classes.menuButton} color="inherit"
												aria-label="menu">
										<MenuIcon/>
									</IconButton>
									<Typography variant="h6" className={classes.title}>
										Login
									</Typography>
									<Button color="inherit">Login</Button>
								</Toolbar>
							</AppBar>
							<Box
								display="flex"
								alignItems="center"
								justifyContent="center"
								flexDirection={"column"}
							>
							<img src={"cfcu_logo.png"} style={{marginTop: 30}}/>
							{/*<StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>*/}
							<SignIn/>
							</Box>
						</div>
					)
				}

			}



			}


		</FirebaseAuthConsumer>

	);
}

export default Home;

import React, {useEffect, useState} from 'react';
import {ChatContainer, MainContainer, Message, MessageInput, MessageList} from "@chatscope/chat-ui-kit-react";
import {
	useParams,
} from "react-router-dom";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import firebase from "firebase/app";
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import { Box } from '@material-ui/core'
import {DateTime} from "luxon";
function ChatBox(props) {
	let { chatID } = useParams();
	const [convo, setConvo] = useState(null);
	let history = useHistory();
	const [msgs, setMsgs] = useState([

	])

	useEffect(()=>{
		console.log("user ", props.user)
		let chats = []
		async function getDocs(){
			await props.db.collection("messages").where("convID", "==", chatID).orderBy("time").onSnapshot((snap) => {
				snap.docChanges().forEach((change)=>{
					if (change.type === "added") {
						setMsgs((msgs) => [...msgs, {"id": change.doc.id, ...change.doc.data()}])
					}

				})
			})

		}

		async function getConvo(){
			let doc = await props.db.collection("convoManager").doc(chatID).get()
			setConvo(doc.data())
		}
		getDocs()
		getConvo()

	}, [chatID])


	return (
		<div style={{ position: "relative", height: "600px" }}>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				flexDirection={"row"}
			>
				<Button
					variant="contained"
					color="primary"
					style={{marginRight: 100}}
					onClick={()=>history.push("/")}
				>
					Back
				</Button>
				<h3>Customer Name: {convo?.name} | Phone: {chatID}</h3>
			</Box>


			<MainContainer>
				<ChatContainer>
					<MessageList>

						{
							msgs.map((msg) => (
								<Message
									model={{
										message: msg.msg,
										sentTime: msg.time.toString(),
										sender: msg.user,
										direction: msg.user === "customer" ? "incoming" : "outgoing",
									}}
									key = {msg.time}
								>

									{msg.image === true && (
										<Message.CustomContent>
											<a href={msg.msg} target="_blank">Click here for attachment</a>
										</Message.CustomContent>
									)}
									<Message.CustomContent>
										<p style={{fontWeight: "bold"}}>{DateTime.fromISO(msg.time.toDate().toISOString()).setZone("America/Los_Angeles").toLocaleString(DateTime.DATETIME_MED)}</p>
									<Message.TextContent text={msg.msg}/>
									</Message.CustomContent>
								</Message>
							))
						}
					</MessageList>
					<MessageInput placeholder="Type message here" onSend={(a, texta, c)=>{

						props.db.collection("messages").add({
							convID: chatID,
							msg: texta,
							time: firebase.firestore.Timestamp.now(),
							user: props.user.displayName,
						})
					}}/>
				</ChatContainer>

			</MainContainer>
			<Button
				color="inherit"
				onClick={()=>{
					props.db.collection("messages").add({
						convID: chatID,
						msg: "Please upload your document at our secure site here: ",
						time: firebase.firestore.Timestamp.now(),
						user: props.user.displayName,
					})
					props.db.collection("messages").add({
						convID: chatID,
						msg: "https://app.loanspq.com/cu/ViewSubmittedLoans.aspx?enc=Kw21Wblm1yxpjJabdoZaD9To0BmgvwcMMNvMGIV_8K61AMzSLiJAhNH7HO-TXmq0SCkrOkeJqoZzRvTzzp1ylg ",
						time: firebase.firestore.Timestamp.now(),
						user: props.user.displayName,
					})
				}}
			>Send Upload Link</Button>
		</div>
	)
}

export default ChatBox

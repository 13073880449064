import React, {useState, useEffect} from 'react';
import { Box } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import DialogContent from "@material-ui/core/DialogContent";
import Button from '@material-ui/core/Button';
import firebase from "firebase/app";
import "firebase/auth";

export default function SignIn(props) {
	const [email, setEmail] = useState("")
	const [pw, setPw] = useState("")
	const [error, setError] = useState("")

	const signIn = async () => {
		setError("")
		try {
			await firebase.auth().signInWithEmailAndPassword(email, pw)
		} catch (e) {
			console.log("login error ", e)
			setError(e.message)
		}

		setEmail("")
		setPw("")
	}

	const onKeyUp = (event) => {
		if (event.charCode === 13) {
			signIn()
		}
	}

	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			flexDirection={"column"}
			style={{width: "100%"}}
		>
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			flexDirection={"column"}
			style={{width: "50%"}}
		>
			<h3 style={{color: "red"}}>{error}</h3>
			<TextField
				autoFocus
				margin="dense"
				id="email"
				label="Email"
				fullWidth
				type={"email"}
				value={email}
				onChange={(e) => setEmail(e.target.value)}
			/>
			<TextField
				autoFocus
				margin="dense"
				id="password"
				label="Password"
				fullWidth
				type="password"
				autoComplete="current-password"
				value={pw}
				onChange={(e) => setPw(e.target.value)}
				onKeyPress={onKeyUp}
			/>
			<Button
				variant="contained"
				color="primary"
				style={{width: "100%", marginTop: 20}}
				onClick={signIn}
			>
				Sign In
			</Button>
		</Box>
		</Box>
	)
}

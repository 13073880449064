import logo from './logo.svg';
import './App.css';
import firebase from "firebase/app";
import "firebase/auth";
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import {FirebaseAuthProvider} from "@react-firebase/auth";
import Home from "./views/Home";
require("firebase/firestore");

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: "AIzaSyA9nRVx37sG3J3nW0AbwZoWRf9ob4OedyQ",
  authDomain: "camino-3abd9.firebaseapp.com",
  projectId: "camino-3abd9",
  storageBucket: "camino-3abd9.appspot.com",
  messagingSenderId: "790027940680",
  appId: "1:790027940680:web:a282e5e12c4e747b81397d",
  measurementId: "G-LXT4ZYQC8S"
};


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#F2BC57',
      main: '#F2BC57',
      dark: '#F2BC57',
      contrastText: '#F2F2F2',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

firebase.initializeApp(firebaseConfig);

function App() {
  var db = firebase.firestore();

  return (
      <ThemeProvider theme={theme}>
      <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>

        <Home db={db}/>
      </FirebaseAuthProvider>
      </ThemeProvider>
  );
}

export default App;

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, {useState,useEffect} from 'react';
import firebase from "firebase/app";
// Required for side-effects
require("firebase/firestore");


export default function NewConversationDialog(props) {
	const {open, setOpen} = props;
	const [name, setName] = useState("")
	const [phone, setPhone] = useState("")

	useEffect(()=>{
		console.log("user ", props.user)
	}, [])

	const handleStart = async () => {
		console.log(name, phone)
		setOpen(false);

		let localPhone = phone;
		localPhone = localPhone.replace("-", "")
		localPhone = localPhone.replace("-", "")

		let doc = await props.db.collection("convoManager").doc("+1"+localPhone).get()

		if (doc.exists) {
			alert(`Customer with phone number ${"1" + localPhone} already exists.`)
			setName("")
			setPhone("")
		} else {
			props.db.collection("convoManager").doc("+1"+localPhone).set({
				"to" : "+1"+localPhone,
				"name" : name,
				"permissionGranted" : false,
				"time" : firebase.firestore.Timestamp.now(),
				"agent" : props.user.email,
				"archived" : false,
			})
			setName("")
			setPhone("")
			props.getDocs()
		}

	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>

			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Start New Conversation</DialogTitle>
				<DialogContent>
					<DialogContentText>
						To start a new conversation, please enter the customer's name and phone number
					</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Name"
						fullWidth
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Phone Number - ex: 5623040498"
						fullWidth
						value={phone}
						onChange={(e) => setPhone(e.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button onClick={handleStart} color="primary">
						Start Conversation
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
